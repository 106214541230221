/* .react-datepicker-popper {
  z-index: 9999 !important;
} */

html {
  overflow-x: hidden;
}

.homeModuleCard {
  margin: 0 14px 16px 0;
  border-radius: 10px !important;
  border: 0 !important;
  outline: solid 1px #e2efff !important;
  min-width: 5;
  height: 64px;
  overflow: initial;
  background: #fff;
  cursor: pointer;
  padding: 0 8px 0 0;
  -webkit-transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
  box-shadow: 0 3px 8px 0 rgba(41, 129, 233, 0.15) !important;
}

.homeModuleCard::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0 14px 16px 0;
  border-radius: 10px !important;
  min-width: 5;
  height: 64px;
  overflow: initial;
  cursor: pointer;
  opacity: 0;
  padding: 0 8px 0 0;
  -webkit-transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
  box-shadow: 0 3px 8px 0 rgba(41, 129, 233, 0.15) !important;
}

#variation-reports-dialog {
  box-shadow: 0 3px 8px 0 rgb(87, 96, 107, 0.15) !important;
  outline: solid 1px #e8e8e8 !important;
}

.homeModuleCard:hover {
  -webkit-transform: scale(1.06, 1.06);
  transform: scale(1.06, 1.06);
}

.homeModuleCard:hover::after {
  -webkit-transform: scale(1.06, 1.06);
  transform: scale(1.06, 1.06);
  opacity: 1;
}

.homeModuleCard .MuiPaper-outlined {
  border: 0;
}

.homeModuleCard:hover .homeModuleCardFavorite {
  display: block !important;
  -webkit-transition: all 0.75s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
  transition: all 0.75s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
}

.homeModuleCardFavorite {
  display: none;
}

.moduleCardsIcon {
  position: absolute;
  color: #04384d;
  margin-top: 10px;
  font-size: 20px;
}

.react-datepicker {
  z-index: 3;
  position: absolute;
  margin: 5px 0 0 10px;
}

.MuiTableRow-root .MuiTableCell-root {
  font-size: 11px !important;
  border-bottom: 1px solid #e3f0f6 !important;
}

.MuiTableRow-root:last-child td {
  border-bottom: 0 !important;
}

.MuiTableCell-sizeSmall {
  padding: 4px 6px !important;
}

.MuiTableCell-stickyHeader {
  background-color: #dbfde2 !important;
  font-size: 11px !important;
  color: #222 !important;
  font-weight: 400;
}

.diffColorRow .MuiTableCell-stickyHeader {
  font-size: 11px !important;
  color: #222 !important;
  font-weight: 400;
}

.MuiTableContainer-root {
  background-color: #fff !important;
}

.MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: #f0fbfc !important;
}

.tooltip {
  font-size: 9px;
  margin: 2px 0;
}

.sp-submenu .MuiTypography-root {
  font-size: 14px !important;
  font-family: 'Work Sans';
}

.sp-popper {
  min-width: 256px;
  background-color: #fff;
  color: #222;
  box-shadow: 0 2px 8px rgba(131, 131, 131, 0.2);
  border-radius: 6px;
  max-width: calc(100% - 100px);
  padding: 10px;
}

.sp-popper .popper-header {
  color: #003471;
  font-size: 13px;
  margin: 5px 0 10px;
}

.sp-popper .popper-body {
  font-size: 12px;
  margin: 10px 0;
  height: 90% !important;
}

.sp-modal-close {
  position: absolute !important;
  top: 2px;
  right: 2px;
}

.sp-modal-back {
  position: absolute !important;
  left: 2px;
  top: 2px;
  width: fit-content;
}

.sp-modal {
  min-width: 300px;
}

.sp-modal.xl .MuiDialog-paperWidthSm {
  max-width: 92% !important;
  max-height: 95vh;
  width: 92% !important;
}

.sp-modal.lg .MuiDialog-paperWidthSm {
  max-width: 85% !important;
  max-height: 95vh;
  width: 85% !important;
}

.sp-modal.xmd .MuiDialog-paperWidthSm {
  max-width: 800px !important;
  width: 70% !important;
  max-height: 95vh;
}

.sp-modal.md .MuiDialog-paperWidthSm {
  max-width: 800px !important;
  width: 55% !important;
  max-height: 95vh;
}

.sp-modal.sm .MuiDialog-paperWidthSm {
  max-width: 800px !important;
  width: 400px !important;
  max-height: 95vh;
}

.sp-modal.xsm .MuiDialog-paperWidthSm {
  max-width: 800px !important;
  width: 36% !important;
  max-height: 95vh;
}

.sp-modal.xs .MuiDialog-paperWidthSm {
  max-width: 800px !important;
  width: 316px !important;
  max-height: 80vh;
}

.sp-modal .MuiDialogTitle-root {
  padding: 10px 15px !important;
  background: #c7eafa !important;
  border-radius: 4px 4px 0 0 !important;
}

::-webkit-calendar-picker-indicator {
  margin-left: 0;
}

.sp-container {
  background: #fff;
  border-radius: 6px;
  border: 1px solid #e3f0f6;
}

.sp-dialog.sm .MuiDialog-paperWidthSm {
  max-width: 800px !important;
  width: 400px !important;
  max-height: 95vh;
  border-radius: 16px;
}

.prevBillsModal .MuiDialogContent-root {
  margin-top: 0 !important;
}

.comboSearch .MuiOutlinedInput-root {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.comboSearch .MuiOutlinedInput-root fieldset {
  border-left: 0 !important;
}

.paymentModeButtonGroup svg {
  padding: 10px;
}

.paymentModeButtonGroup div > div:nth-child(1) svg path {
  fill: #003471;
}

.paymentModeButtonGroup .sp-active div > div:nth-child(1) svg path {
  fill: #fff;
}

.paymentModeButtonGroup button {
  position: relative;
}

.profileDropDown {
  position: absolute;
  top: 44px;
  right: 83px;
  background-color: #003471;
  width: 240px;
  border-radius: 6px;
  box-shadow: 0 1px 8px 2px rgba(130, 130, 130, 0.4);
  transition: 0s;
  z-index: 1000;
}

.profileDropDown::before {
  content: '';
  position: absolute;
  top: -4px;
  right: 13px;
  background-color: #003471;
  width: 16px;
  height: 16px;
  transform: rotate(45deg);
  border-radius: 1px;
}

.profileDropDown ul {
  list-style: none;
  padding: 0;
  background-color: #fff;
  margin: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.profileDropDown li {
  cursor: pointer;
  padding: 4px 8px;
  height: 30px;
}

.profileDropDown li:hover {
  cursor: pointer;
  padding: 4px 8px;
  background-color: #eaf0f3;
  height: 30px;
}

.importRow:hover {
  background-color: #000 !important;
}

#linkStyleLabelValueSet {
  color: #1e6cc7;
  margin: 5px 0;
  font-size: 11px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.receiptsPayementDataGrid {
  border: 0 !important;
}

.receiptsPayementDataGrid .MuiDataGrid-columnsContainer {
  font-size: 9px;
  min-height: 27px !important;
  max-height: 27px !important;
  line-height: 32px !important;
}

.receiptsPayementDataGrid .MuiDataGrid-window {
  top: 27px !important;
}

.receiptsPayementDataGrid .MuiDataGrid-row {
  font-size: 10px;
  min-height: 28px !important;
  max-height: 28px !important;
}

.receiptsPayementDataGrid .MuiDataGrid-cell {
  min-height: 28px !important;
  max-height: 28px !important;
  line-height: 30px !important;
}

.discountMasterDataGrid .Mui-error {
  background-color: rgb(255, 141, 146, 0.2) !important;
  color: #ff4343;
}

.discountMasterDataGrid .Header {
  background-color: #dbfde2;
  color: #222;
}

.discountMasterDataGrid .MuiDataGrid-cell.MuiDataGrid-cell--editing {
  box-shadow: none !important;
  background-color: rgb(255, 215, 115, 0.15) !important;
  color: #1a3e72;
}

.crDbNoteDataGrid .Mui-error {
  background-color: rgb(255, 141, 146, 0.2) !important;
  color: #ff4343;
}

.crDbNoteDataGrid .MuiDataGrid-cell.MuiDataGrid-cell--editing {
  box-shadow: none !important;
  background-color: rgb(255, 215, 115, 0.15) !important;
  color: #1a3e72;
}

.purchaseOrderDataGrid .Mui-error {
  background-color: rgb(255, 141, 146, 0.2) !important;
  color: #ff4343;
}

.purchaseOrderDataGrid .Header {
  background-color: #dbfde2;
  color: #222;
}

.purchaseOrderDataGrid .MuiDataGrid-cell.MuiDataGrid-cell--editing {
  box-shadow: none !important;
  background-color: rgb(255, 215, 115, 0.15) !important;
  color: #1a3e72;
}

.purchaseOrderDataGrid .MuiDataGrid-columnHeaderTitleContainer {
  padding: 0 !important;
}

.purchaseOrderDataGrid .MuiDataGrid-columnsContainer {
  background-color: #dbfde2 !important;
}

.purchaseOrderDataGrid .highlight {
  background-color: #b9fad9;
}

.purchaseOrderPagination .MuiTablePagination-toolbar {
  min-height: 36px !important;
}

.accountGroupAndLedger .MuiTableRow-root .MuiTableCell-root {
  border: none !important;
  border-bottom: none !important;
}

.boxContainer {
  background: #f4fafe;
  border-radius: 12px;
  padding: 10px;
}

.flexCenterBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.searchConfigForm .MuiTypography-body1 {
  font-size: 13px;
}

.blueTableHeaderCell {
  background-color: #ceeaf7 !important;
}

.yellowTableHeaderCell {
  background-color: #fbe7c8 !important;
}

.homeModuleCard .MuiCardContent-root:last-child,
#ReceiptsPaymentsMainCard .MuiCardContent-root:last-child,
#VouchersMainCard .MuiCardContent-root:last-child,
#DoctorTargetsCard .MuiCardContent-root:last-child,
#RemoveCardBottomPadding .MuiCardContent-root:last-child,
#HqDashboardCards .MuiCardContent-root:last-child,
#StockAuditMainCard .MuiCardContent-root:last-child,
#inventoryHeaderCard .MuiCardContent-root:last-child,
#schemeVerificationCard .MuiCardContent-root:last-child,
#chequeBookCard .MuiCardContent-root:last-child,
#bottomPadRemovedCard .MuiCardContent-root:last-child,
#createOfferHeader .MuiCardContent-root:last-child,
#excessSchemesHeader .MuiCardContent-root:last-child,
#batchHoldingHeader .MuiCardContent-root:last-child,
#inventoryEntryTableCard .MuiCardContent-root:last-child {
  padding-bottom: 0;
}

#moreReportsDialog .MuiDialog-paper {
  overflow-x: hidden;
}

#crDbNoteDataGrid {
  height: 100%;
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

#crDbNoteDataGrid,
#receiptsPaymentsDataGrid,
#poDataGrid {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

#crDbNoteDataGrid .MuiDataGrid-columnsContainer div > div {
  background-color: #dbdbdb;
  color: #222;
}

#receiptsPaymentsDataGrid .MuiDataGrid-columnsContainer div > div {
  background-color: #dbfde2;
  color: #222;
}

#crDbNoteDataGrid .Added,
#receiptsPaymentsDataGrid .Added {
  background-color: #d5f4ed;
}

#crDbNoteDataGrid .Partial,
#receiptsPaymentsDataGrid .Partial {
  background-color: #f7fed9;
}

#crDbNoteDataGrid .Deducted,
#receiptsPaymentsDataGrid .Deducted {
  background-color: #ffd3c8;
}

#crDbNoteDataGrid .MuiDataGrid-cell--editing,
#receiptsPaymentsDataGrid .MuiDataGrid-cell--editing,
#poDataGrid .MuiDataGrid-cell--editing {
  background-color: rgb(255, 215, 115, 0.19);
  color: #1a3e72;
}

#crDbNoteDataGrid .Mui-error,
#receiptsPaymentsDataGrid .Mui-error,
#poDataGridContainer .Mui-error {
  background-color: rgb(255, 125, 131, 0.2);
  color: #ff4343;
}

/****************************************************   */

#poDataGridContainer {
  .NoImport {
    background-color: #ececec;
    color: #575757;
    border-right: 1px solid #d1d1d1;
  }

  .MuiDataGrid-columnsContainer div > div {
    background-color: #dbfde2;
  }

  .MuiDataGrid-columnHeaderTitleContainer {
    padding: 0;
  }

  .MuiDataGrid-iconSeparator {
    display: none;
  }

  .editableCell {
    background-color: #fffce8;
  }

  .MuiDataGrid-row:hover .editableCell {
    background-color: #fcf8da;
  }
}

/****************************************************   */

#schemeVerificationDataGrid {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

#schemeVerificationDataGrid .MuiDataGrid-columnsContainer div > div {
  font-size: 10px;
  font-weight: 400;
  background-color: #dbfde2;
  color: #222;
}

#schemeVerificationDataGrid .Added {
  background-color: #f4fec7;
}

#schemeVerificationDataGrid .Deducted {
  background-color: #ffd3c8;
}

#schemeVerificationDataGrid .MuiDataGrid-cell--editing {
  background-color: rgb(255, 215, 115, 0.19);
  color: #1a3e72;
}

#schemeVerificationDataGrid .Mui-error {
  background-color: rgb(126, 10, 15, 0.1);
  color: #ff4343;
}

#schemeVerificationDataGrid .editableCell {
  background-color: #fffdf0;
}
