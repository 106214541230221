@import '../../components/variables.css';

.root {
  padding-left: 20px;
  padding-right: 20px;
}

.container {
  margin: 0 auto;
  padding: 0 0 40px;
  max-width: var(--max-content-width);
}

.app {
  width: '100%';
}
