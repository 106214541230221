@import 'src/components/variables.css';

.root {
  padding: 0 16px 16px 16px;
  background-color: #f4faff;
  height: calc(100% - 76px);
  margin-left: 48px;
  overflow: hidden;
}

.container {
  height: 100%;
  width: 100%;
  padding-top: 10px;
}

.ruleMain {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: calc(100vh - 90px);
  width: 80%;
}

.flexVCenter {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.ruleBox1 {
  padding: 4px 0;
  height: 100%;
  width: calc(20% - 24px);
  margin-right: 24px;
}

.ruleBox2 {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: rgb(255, 255, 255);
  outline: 1px solid rgb(227, 240, 246);
  overflow: auto;
}

.ruleCardContainer {
  display: flex;
  gap: 4px;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  padding: 0;
}

.ruleCard {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 8px;
  cursor: pointer;
  border-radius: 8px;
  height: 32px;
}

.ruleCard.active {
  background-color: #dbf0fa;
}

.templateCard {
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(54, 80, 90, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  padding: 10px;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
}

.selectedRoleDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #c7eafa;
}

.ruleCard .label {
  color: #757575;
  font-size: 11px;
}

.ruleCard .ruleName {
  color: #475054;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.1px;
}

.ruleCard path {
  stroke: #87a9b8 !important;
}

.ruleCard.active .ruleName,
.ruleCard.active .label,
.ruleCard.active path {
  color: #2263af !important; /* blue */
  stroke: #2263af !important; /* blue */
}

.ruleCard .createdDetailsWraper {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.templateCard .templateName {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.1px;
}

.templateCard.active {
  background-color: #397bc8; /* blue */
  color: #fff !important;
}

.sectionTitle {
  font-weight: 500;
  font-size: 16px;
  padding: 0 16px 0 0;
  color: #003471;
  border-radius: 16px 16px 0 0;
}

h3.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #003471;
  margin: 8px 0;
}

.subTitle {
  font-weight: 400;
  font-size: 13px;
  display: flex;
  align-items: center;
  color: #688795;
  margin: 8px 0 16px;
}

.text {
  width: calc(100% - 10px);
  height: 50px;
  margin-bottom: 10px;
}

.textbox {
  width: calc(100% - 10px);
  margin: 8px 0;
  background-color: #fff;
}

.textbox2 {
  width: calc(100% - 10px);
  margin: 5px 0;
  background-color: #fff;
}

.groupSearchBar {
  width: 80%;
  height: 45px;
  margin: 12px 0 16px 32px;
}

.border {
  margin-top: 24px;
  margin-bottom: 56px;
}

.editButton {
  width: 16px;
  height: 16px;
  float: right;
  position: absolute;
  bottom: 4%;
  right: 18px;
}

.tabArea {
  height: 100%;
  width: 100%;
  zoom: 85%;
}

.margin {
  width: calc(100% - 16px);
  bottom: 0;
  margin: 2px 0 10px 0;
  background-color: #fff;
}

.csvpanel {
  width: 100%;
  min-height: 360px;
}

.previewPanel {
  width: 100%;
  min-height: 272px;
}

.textInput {
  width: calc(100% - 10px);
  margin-right: 10px;
  background-color: #fff;
}

.columnInput {
  width: calc(100% - 64px);
  padding: 0;
  margin: 0;
  background-color: #eaf2f5;
}

.usageDropdown {
  z-index: 998 !important;
  font-size: 20;
}

.textButton {
  margin: 4px 0 0 20px;
  font-size: 14px;
  display: inline-block;
}

.textButton:hover {
  text-decoration: underline;
  cursor: pointer;
}

.borderBox {
  border-style: solid;
  border-width: thin;
  border-color: #3c98e8;
  border-radius: 6px;
  padding: 15px 10px 15px 10px;
  margin-bottom: 16px;
  height: 180px;
  width: calc(100% - 21px);
  background-color: #eaf2f5;
}

.fieldsetBorder {
  border: solid;
  border-width: 2;
  border-color: #1c5268;
  margin-right: 0;
  border-radius: 6px;
  margin-bottom: 5px;
  height: auto;
  background: #fff;
  padding: 4px 0 12px 8px;
}

.fieldsetLegend {
  margin: 2px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #1c5268;
}

.selectfield {
  margin: 6px 0 0 0;
  width: 90%;
}

.selectfield2 {
  margin: 2px 0 0 0;
  width: 90%;
}

.checkedGreenContainer {
  height: 24px;
  width: 24px;
  background-color: #07b67b;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px 0;
}

/* ....Tabs. */
.settingsTabs {
  width: 172px;
}

.settingsTabsIndicator {
  width: 4px;
  background: #003471;
}

.moduleSettingsTabs {
  color: #222;
  background: #f4fafe;
  font-weight: 400;
}

.moduleSettingsTabsIndicator {
  background: #222;
}

.moduleSettingsTabs .MuiTabs-indicator {
  background: #222;
}

.moduleSettingsTab {
  text-transform: none;
  font-size: 15px;
  min-width: 116px;
  font-weight: 300;
}

.moduleSettingsTabDisabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
  color: #707070;
}

.moduleSettingsTabSelected {
  color: #003471;
  font-weight: 700;
}

.parentTabDisabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
  color: #707070;
}

.parentTabSelected {
  color: #003471;
  background-color: #fff;
  font-weight: 700;
}

.parentTabsIndicator {
  width: 2px;
  background-color: #fff;
}

.backdrop {
  z-index: 9999 !important;
  color: #3c98e8;
}

.style_input {
  height: max-content;
  width: 100%;
  color: #222;
  position: relative;
  z-index: 1;
  padding: 8px;
}

.reportCustomizationDialogHeading {
  color: #003471;
  font-weight: 500;
  font-size: 14px;
}

.reportCustomizationDialogContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid #e3f0f6;
  border-radius: 6px;
  padding: 8px 12px;
  margin-top: 8px;
  height: 320px;
}

.reportCustomizationDialogAdd {
  color: #003471;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 16px;
}

.reportCustomizationDialogScrollContainer {
  background-color: #f5fcff;
  margin-top: 8px;
  border-radius: 6px;
  width: 100%;
  max-height: 256px;
  overflow: auto;
}

.dayContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #e3f0f6;
  border-radius: 6px;
  height: 28px;
  width: 28px;
  font-weight: 500;
  cursor: pointer;
}

.dayContainer.active {
  background-color: #2981e9;
  color: #fff;
}

.newfeature {
  justify-content: space-between;
  margin-bottom: 16px;
  border-radius: 6px;
  background-image: linear-gradient(45deg, #eaffc1, #e0fff6);
}

.subHeads {
  color: #003471;
  margin: 36px 0 12px;
  font-size: 13px;
}

.expandBars {
  background-color: #e1f1fb;
  padding: 5px 0;
  justify-content: center;
  border-radius: 0 0 8px 8px;
  cursor: pointer;
}
