@import '../../components/variables.css';

.a4_page {
  background-color: #fff;
  box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.3);
  margin-top: 32px;
  width: max-content;
}

.content_container {
  margin: 26px 26px 0;
  height: calc(100% - 104px);
  outline: solid 3px #808080;
}

.header_container {
  background-color: #eceff2;
}

.firm_name {
  margin: 16px 0 14px;
  font-weight: 600;
  text-align: center;
  font-size: 26px;
  color: #003471;
}

.firm_address {
  margin: 0 0 8px;
  font-weight: 400;
  text-align: center;
  font-size: 14px;
}

.report_title {
  margin: 0 0 0 16px;
  font-weight: 500;
  font-size: 18px;
  color: #003471;
}

.report_subTitle {
  margin: 6px 0 6px 16px;
  font-weight: 400;
  font-size: 15px;
}

.page_number {
  font-weight: 400;
  text-align: right;
  font-size: 14px;
  padding-right: 16px;
  margin: 0;
}

.header_divider {
  margin: 20px 0 0;
  border-top: 3px dotted #808080;
}

.report_details_container {
  padding: 16px 0 8px 0;
  border-bottom: solid 2px #808080;
}

.footer_container {
  padding: 8px 24px;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
}

.table_header_container {
  border-bottom: solid 2px #808080;
  background-color: #ddfaec;
  align-items: center;
  width: 100%;
  font-size: 13.5px;
}

.table_row_container {
  border-bottom: solid 1px #dbdbdb;
  align-items: center;
  font-size: 13.5px;
  width: 100%;
  color: #000;
}

.additional_header_container {
  border-bottom: solid 2px #808080;
  align-items: center;
  width: 100%;
  font-size: 13.5px;
}
