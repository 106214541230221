.root {
  /* margin-left: 48px; */
  background-color: #f4faff;
  height: calc(100% - 90px);
  zoom: 90%;
}

.container {
  height: 100%;
  padding: 10px 16px 16px 25px;
  width: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.margin {
  width: 100%;
  margin: 0 0 4px 0;
  background-color: #fff;
}

.image_button {
  width: 30px;
  height: 30px;
  margin: 10px;
  cursor: pointer;
}

.checkbox {
  margin: 10px;
}

.tabpanel {
  width: 1200px;
}

.border {
  border-style: solid;
  border-width: thin;
  border-color: #3c98e8;
  border-radius: 6px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: -15px;
  height: 370px;
  width: 950px;
  background-color: #ffffed;
}

.divider {
  height: 1px;
  background: #d3d3d3;
  margin: 10px 0 10px 0;
}

.topborder {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  border-radius: 8px;
  padding: 12px;
  background-color: #e5f3f8;
  outline: 1px solid #e3f0f6;
  box-shadow: 0 2px 6px 0 rgb(146, 178, 192, 0.7);
  overflow: visible;
  outline: solid 1px #b5d2dd;
}

.myfieldset {
  padding: 16px;
  border-radius: 6px;
  background: #fff;
  border: solid 1px #3c98e8;
}

.detailsfieldset {
  border: solid;
  border-width: thin;
  border-color: #3c98e8;
  margin-left: 10px;
  border-radius: 4px;
  margin-top: 0;
  margin-bottom: 10px;
  padding: 0 8px;
  width: 100%;
}

.inputfieldset {
  margin: 10px 0 0 5px;
  width: calc(100% - 10px);
  background-color: #fff;
  min-height: 40px;
}

.legend {
  margin: 2px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 13px;
  font-weight: bold;
}

.backdrop {
  z-index: 2999 !important;
  color: #3c98e8;
}

.pdf_button {
  height: 22px;
  cursor: pointer;
  margin-right: 16px;
}

.select {
  height: 100%;
  width: 100%;
  margin: 0;
  z-index: 998 !important;
}

.summaryPrimaryCard {
  flex-direction: column;
  border: solid 1px #a2ccda;
  border-radius: 6px;
  background-color: #f5fcff;
}

.summaryPrimaryLabel {
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
  padding: 10px;
  color: #5c5c5c;
}

.summaryPrimaryValue {
  display: flex;
  justify-content: flex-end;
  font-size: 22px;
  color: #1b6dcd;
  font-weight: 600;
  padding: 0 10px 10px;
}

.summarySecondaryLabel {
  display: flex;
  justify-content: flex-end;
  font-size: 13px;
  padding: 15px 0 0 0;
  color: #636363;
}

.summarySecondaryValue {
  display: flex;
  justify-content: flex-end;
  font-size: 15px;
  font-weight: 500;
  padding: 15px 0 0 0;
  color: #363636;
}

.settingsLink {
  /* font-size: 12px; */
  color: #0060d0;
  font-weight: 500;
  text-align: right;
  text-decoration: underline;
  margin-right: 24px;
  cursor: pointer;
}
