@import '../variables.css';

.margin {
  margin: '10px 10px 0 10px';
  width: calc(100% - 10px);
}

.headerValues {
  margin: 0;
  display: inline-flex;
  font-size: 14px;
  font-weight: 500;
}

.headerLabels {
  margin: 0;
  display: inline-flex;
  font-size: 11px;
}
