:root {
  /*
   * Typography
   * ======================================================================== */

  --font-family-base: 'Work Sans', 'Roboto', sans-serif;

  /*
   * Layout
   * ======================================================================== */

  --max-content-width: 1000px;

  /*
   * Media queries breakpoints
   * ======================================================================== */

  --screen-xs-min: 480px; /* Extra small screen / phone */
  --screen-sm-min: 768px; /* Small screen / tablet */
  --screen-md-min: 992px; /* Medium screen / desktop */
  --screen-lg-min: 1200px; /* Large screen / wide desktop */
}
